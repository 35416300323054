import React, { Suspense, lazy } from "react"
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Loading from './component/Loading/Loading'
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";
import ProductSansRegular from './asset/Product Sans Regular.ttf';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const SansRegular = {
  fontFamily: 'SansRegular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('SansRegular'),
    local('SansRegular-Regular'),
    url(${ProductSansRegular}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const LazyApp = lazy(() => import("./App"))
let theme = createMuiTheme({
  typography: {
    fontFamily: 'SansRegular, Arial',
    button: {
      textTransform: 'none',
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [SansRegular],
        '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          marginTop: '0px'
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '16px',
      },
      contained: {
        boxShadow: 'none'
      }
    },
    MuiPaper: {
      root: {
        background: 'linear-gradient(0deg, #FAAE50, #FBD14C)',
      },
    },
    // MuiStepConnector:{
    //   line: {
    //     display: 'none'
    //   }
    // },
  },
  palette: {
    primary: {
      main: "#768EC7",
    },
    secondary: {
      main: "#763EC7",
    },
    tertiary: {
      main: '#1A8DCC',
    },
    quaternary: {
      main: '#9372B1',
    },
    success: {
      main: "#60BB55",
    },
    bukalapak: {
      main: "#DD2153"
    },
    kemnaker: {
      main: "#F6F6F6"
    },
    tokopedia: {
      main: "#60BB55"
    },
    cs: {
      main: '#F05246'
    }
  },
});

theme = responsiveFontSizes(theme); 

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_ID,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });
  
ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading />}>
          <LazyApp />
        </Suspense>
      </ThemeProvider>
    </Provider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
